import { tns } from "../node_modules/tiny-slider/src/tiny-slider"

var slider = tns({
  container: '.slider__home',
  items: 1,
  slideBy: 'page',
  mode: 'gallery',
  animateDelay: true,
  autoplay: false,
  autoplayTimeout: 7000,
  speed: 700,
  autoplayButtonOutput: false,
  nav: false,
  controls: true,
  prevButton: '.sliderNavArrow.prev',
  nextButton: '.sliderNavArrow.next',
  touch: true,
  swipeAngle: 0,
  preventScrollOnTouch: "auto",
});


// animations
